.messageList {
    overflow: hidden;
    margin-bottom: 72px;
    padding-left: 8px;
}

.messageList::-webkit-scrollbar {
    width: 0em;
}

.inputContainer {
    border-radius: 16px;
    display: flex;
    align-items: center;
    border: 1px solid #d0d0d0;
    position: absolute;
    bottom: -1px;
    right: -1px;
    left: -1px;
    z-index: 9999;
    background-color: white;
    /* padding-bottom: 8px;
    padding-top: 8px; */
}

.rce-container-input .inputContainer {
    padding-bottom: 16px;
    align-items: flex-start !important;
    border-color: white !important;
}

.ant-segmented-item-label {
    padding-top: 8px !important;
}

.sendMess:hover {
    cursor: pointer;
}

.scrollChat::-webkit-scrollbar {
    width: 0px;
}

.segmentGame::-webkit-scrollbar {
    /* width: 0px; */
}

.messListChat::-webkit-scrollbar {
    width: 0px;
}