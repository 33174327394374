.btn-game {
    position: relative;
    width: calc(100% /4 - 1em);
    height: 6em;
    display: flex;
    align-items: center;
    border-radius: 18px;
    padding: 10px;
    overflow: hidden;
    margin: 0 0.5em 1em;
    background: #f2f2f2;
    /* background: linear-gradient(#efb762 0 0) calc(100% - var(--p, 0%)) / var(--p, 0%) no-repeat; */
    /* transition: .4s, background-position 0s; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */
    /* background-image: url(https://support.vnggames.com/images/img/hov.png) */
}

.btn-game::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 6em;
    background-image: url(https://support.vnggames.com/images/img/hov.png);
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

.btn-game:hover {
    transition: .10s, background-position 0s;
    --p: 100%;
    transition: 0.2s;
    cursor: pointer;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4);
    /* background: linear-gradient(#efb762 0 0) calc(100% - var(--p, 0%)) / var(--p, 0%) no-repeat; */
    background: linear-gradient(159.68deg, #ffe6a1, #fdd065);
    animation: raising .4s forwards;
    -webkit-animation: raising .4s forwards;
}

.login-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: calc(100% / 5)
}

.d-flex-modal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (max-width: 840px) {
    .btn-game {
        width: calc(100% - 1em);
    }

    .login-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: auto;
        width: calc(100% / 2)
    }
}